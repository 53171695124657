var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-menu',_vm._g(_vm._b({scopedSlots:_vm._u([_vm._l((_vm.$scopedSlots),function(_,name){return {key:name,fn:function(slotData){return [_vm._t(name,null,null,slotData)]}}})],null,true),model:{value:(_vm.model.value),callback:function ($$v) {_vm.$set(_vm.model, "value", $$v)},expression:"model.value"}},'v-menu',_vm.options.attrs,false),_vm.options.on),[_vm._l((_vm.$slots),function(_,name){return _vm._t(name,null,{"slot":name})}),_c('div',{directives:[{name:"x-autofocus",rawName:"v-x-autofocus",value:(_vm.options.content.autofocus),expression:"options.content.autofocus"}]},[(_vm.options.content.items)?_c('v-list',[_vm._l((_vm.options.content.items),function(item,key){return [(item && (!item.ext || !item.ext.condition || item.ext.condition(_vm.self)))?_c('v-list-item',{key:key,on:{"click":function($event){_vm.targets[key] ? (_vm.targets[key].value = true) : _vm.onSelect(item, key)}}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item.content.text))]),(item.content.icon)?_c('v-icon',{attrs:{"right":""}},[_vm._v(_vm._s(item.content.icon))]):_vm._e()],1)],1):_vm._e()]})],2):(_vm.options.content.rawContent && _vm.options.content.rawContent.type)?_c('div',[_c(_vm.options.content.content.type,_vm._b({tag:"component",attrs:{"x-container":_vm.rawContentContainer,"x-context":_vm.self}},'component',
            _vm.options.content.content.makeAttrs
              ? _vm.options.content.content.makeAttrs(_vm.options.content.content.attrs, _vm.self)
              : _vm.options.content.content.attrs
          ,false))],1):(
          _vm.options.content.cardContent &&
          _vm.options.content.cardContent.content &&
          _vm.options.content.cardContent.content.type
        )?_c('div',[_c('v-card',_vm._b({},'v-card',_vm.options.content.cardContent.attrs,false),[(_vm.cardContentTitleHTML)?[_c('v-list',[_c('v-list-item',[_c('v-list-item-content',[(_vm.cardContentTitleHTML)?_c('v-list-item-title',{domProps:{"innerHTML":_vm._s(_vm.cardContentTitleHTML)}}):_vm._e(),(_vm.cardContentSubTitleHTML)?_c('v-list-item-title',{domProps:{"innerHTML":_vm._s(_vm.cardContentSubTitleHTML)}}):_vm._e()],1),(
                    _vm.cardContentContainer.value && Object.keys(_vm.options.content.cardContent.topButtons).length !== 0
                  )?_c('v-list-item-action',[_vm._l((_vm.options.content.cardContent.topButtons),function(btn,key){return [(!btn.ext || (!btn.ext.hidden && (!btn.ext.condition || btn.ext.condition(_vm.self))))?_c('x-basic-btn',{key:key,attrs:{"x-options":btn,"x-context":_vm.self,"x-container":_vm.cardContentTopButtonContainers[key]}}):_vm._e()]})],2):_vm._e()],1)],1),_c('v-divider')]:_vm._e(),_c('div',[_c(_vm.options.content.cardContent.content.type,_vm._b({tag:"component",attrs:{"x-container":_vm.cardContentContainer,"x-context":_vm.self}},'component',
                _vm.options.content.cardContent.content.makeAttrs
                  ? _vm.options.content.cardContent.content.makeAttrs(_vm.options.content.cardContent.content.attrs, _vm.self)
                  : _vm.options.content.cardContent.content.attrs
              ,false))],1),(_vm.cardContentContainer.value && Object.keys(_vm.options.content.cardContent.buttons).length !== 0)?_c('v-card-actions',{directives:[{name:"show",rawName:"v-show",value:(!_vm.cardContentHideBottomActions),expression:"!cardContentHideBottomActions"}]},[_c('div',{staticClass:"flex-grow-1"}),_vm._l((_vm.options.content.cardContent.buttons),function(btn,key){return [(!btn.ext || (!btn.ext.hidden && (!btn.ext.condition || btn.ext.condition(_vm.self))))?_c('x-basic-btn',{key:key,attrs:{"x-options":btn,"x-context":_vm.self,"x-container":_vm.cardContentButtonContainers[key]}}):_vm._e()]})],2):_vm._e()],2)],1):_vm._e()],1)],2),(_vm.options.content.items)?[_vm._l((_vm.options.content.items),function(item,key){return [(item)?_c('div',{key:key},[(item.target && item.target.confirm)?_c('x-confirm-dialog',{attrs:{"x-options":item.target.confirm,"x-context":_vm.self,"x-model":_vm.targets[key]}}):_vm._e(),(item.target && item.target.dialog)?_c('x-dialog',{attrs:{"x-options":item.target.dialog,"x-context":_vm.self,"x-model":_vm.targets[key]}}):_vm._e()],1):_vm._e()]})]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }