































































































































import defaultComponent from "../utils/defaultComponent";
import DataContainer from "../common/DataContainer";
import merge from "../utils/merge";

export default defaultComponent.apply({
  props: [],
  data() {
    return {
      targets: [],
      selfDisabled: false,
      rawContentContainer: new DataContainer(),
      cardContentContainer: new DataContainer(),
      cardContentButtonContainers: {},
      cardContentTopButtonContainers: {},
      cardContentHideBottomActions: false,
    };
  },
  computed: {
    disabledState() {
      if (this.enabled) {
        return !this.enabled.value;
      }
      if (this.disabled) {
        return this.disabled.value;
      }
      return this.selfDisabled;
    },
    cardContentTitleHTML() {
      if (typeof this.options.content.cardContent.title === "function") {
        return this.options.content.cardContent.title(this);
      }
      return this.options.content.cardContent.title;
    },
    cardContentSubTitleHTML() {
      if (typeof this.options.content.cardContent.subTitle === "function") {
        return this.options.content.cardContent.subTitle(this);
      }
      return this.options.content.cardContent.subTitle;
    },
  },
  methods: {
    initialize() {
      if (this.options.content.items) {
        for (const key in this.options.content.items) {
          const item = this.options.content.items[key];
          if (item && item.target) {
            this.targets[key] = new DataContainer(false);
          }
        }
      }

      if (this.options.content.cardContent.cancelDisabled) {
        delete this.options.content.cardContent.buttons.cancel;
      }

      for (const key in this.options.content.cardContent.buttons) {
        let button = this.options.content.cardContent.buttons[key];
        if (!button.ignoreDefault) {
          button = merge.clone(this.options.content.cardContent.template.button, button);
        }
        this.options.content.cardContent.buttons[key] = button;
        this.cardContentButtonContainers[key] = new DataContainer();
      }

      for (const key in this.options.content.cardContent.topButtons) {
        let button = this.options.content.cardContent.topButtons[key];
        if (!button.ignoreDefault) {
          button = merge.clone(this.options.content.cardContent.template.topButton, button);
        }
        this.options.content.cardContent.topButtons[key] = button;
        this.cardContentTopButtonContainers[key] = new DataContainer();
      }
    },
    setDisabled(value: boolean) {
      if (this.enabled) {
        this.enabled.value = !value;
      } else if (this.disabled) {
        this.disabled.value = value;
      } else {
        this.selfDisabled = value;
      }
    },
    show() {
      this.model.value = true;
    },
    hide() {
      this.model.value = false;
    },
    onSelect(item, key) {
      if (item.on && item.on.xClick) {
        item.on.xClick({
          self: this,
          item,
          key,
        });
      } else {
        this.$emit("select", {
          item,
          key,
          menu: this,
        });
      }
    },
  },
  extraOptions: {
    defaultClass: "menu",
    forwardStates: {
      xModel: ["model", false],
      xEnabled: ["enabled", false, true],
      xDisabled: ["disabled", false, true],
    },
  },
});
